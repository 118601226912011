import background from './images/background.jpg';
import './App.css';

function App() {
  return (
    <div className="App" >
      <div className='Cover'>
      <header className="App-header"></header>

      <div className="App-content">
        <div>Upcoming concept, more info coming soon</div> 
        <div><small><small>First event will occur in january 2024</small></small></div> 
      </div>
      <footer className='App-footer'>info@cloudsolution.se</footer>
      </div>
    </div>
  );
}

export default App;
